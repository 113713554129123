import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // 确保样式文件正确导入
import App from './App'; // 确保 App 组件正确导入

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);