import React from 'react';
import PotentialCustomerTable from './PotentialCustomerTable'; // 确保 PotentialCustomerTable 组件正确导入

function App() {
  return (
    <div className="App">
      <div className="centered-content">
        <h1>潜在购买意向用户分析</h1>
      </div>
      <PotentialCustomerTable />
    </div>
  );
}

export default App;