import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Table, Spin, Button, DatePicker, Space, Tooltip, ConfigProvider, Select, message} from 'antd';
import 'antd/dist/antd.css';
import * as XLSX from 'xlsx';
import moment from 'moment';
import zhCN from 'antd/es/locale/zh_CN';

const PotentialCustomerTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [startTime, setStartTime] = useState(moment().subtract(24, 'hours').startOf('day')); // 初始化时间保留到天
    const [isRecommendedOnly, setIsRecommendedOnly] = useState(true); // 新增状态，默认选中
    const [isUnpurchasedOnly, setIsUnpurchasedOnly] = useState(false); // 新增状态，默认不选中
    const [schoolList, setSchoolList] = useState([]); // 新增状态，存储学校列表
    const [selectedSchool, setSelectedSchool] = useState('<ALL>'); // 新增状态，默认选中全部

    // 获取学校列表的函数
    const fetchSchoolList = async () => {
        try {
            const response = await axios.get('https://wx.toplion.com.cn/index.php?rid=getPotentialSchoolList&token=sdsdsd&clientOSType=wechat');
            if (response.data && response.data.data && Array.isArray(response.data.data)) {
                setSchoolList(response.data.data.map(item => ({
                    schoolName: item.schoolName,
                    schoolCode: item.schoolCode
                }))); // 修改为存储 schoolName 和 schoolCode
            } else {
                console.error('No data found in response or data is not an array');
                // 显示未检索到学校的提示窗
                message.warning('未检索到学校，请稍后再试或联系管理员。');
            }
        } catch (error) {
            console.error('Error fetching school list: ', error);
            // 显示错误提示窗
            message.error('获取学校列表失败，请检查网络连接或联系管理员。');
        }
    };

    useEffect(() => {
        fetchSchoolList();
    }, []);

    // 导出为 Excel 的函数
    const exportToExcel = () => {
        const headers = columns.map(col => col.title); // 获取表头
        const dataWithHeaders = [headers, ...data.map(row => columns.map(col => row[col.dataIndex]))]; // 将表头和数据合并
        const worksheet = XLSX.utils.aoa_to_sheet(dataWithHeaders); // 使用 aoa_to_sheet 方法将数据和表头一起转换为工作表
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, '潜在客户数据.xlsx');
    };

    useEffect(() => {
        const fetchData = async () => {
            // 在请求前重置分页到第一页
            setPagination(prev => ({ ...prev, current: 1 }));
            setLoading(true);
            try {
                const formattedStartTime = startTime.format('YYYY-MM-DD'); // 格式化时间仅保留到天
                const response = await axios.get(`https://wx.toplion.com.cn/index.php?rid=queryPotentialCustomer&schoolCode=${selectedSchool === '<ALL>' ? '' : selectedSchool}&token=sdsdsd&clientOSType=wechat&startTime=${formattedStartTime}&isRecommendedOnly=${isRecommendedOnly}&isUnpurchasedOnly=${isUnpurchasedOnly}`); // 新增 selectedSchool 参数
                console.log('API Response:', response.data);
                if (response.data && response.data.data) {
                    setData(response.data.data);
                    setPagination(prev => ({
                        ...prev,
                        total: response.data.total || response.data.data.length,
                    }));
                } else {
                    console.error('No data found in response');
                }
            } catch (error) {
                console.error('Error fetching data: ', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [startTime, isRecommendedOnly, isUnpurchasedOnly, selectedSchool]); // 添加 selectedSchool 作为依赖

    const columns = React.useMemo(
        () => [
            {
                title: '序号',
                key: 'index',
                render: (text, record, index) => (pagination.current - 1) * pagination.pageSize + index + 1,
            },
            {
                title: '学校',
                dataIndex: 'schoolName',
                key: 'schoolName',
                sorter: (a, b) => a.schoolName.localeCompare(b.schoolName), // 添加学校名称排序
            },
            {
                title: '账号',
                dataIndex: 'userId',
                key: 'userId',
            },
            {
                title: '姓名',
                dataIndex: 'username',
                key: 'username',
            },
            {
                title: '联系方式',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
                title: '最后访问时间',
                dataIndex: 'modifyTime',
                key: 'modifyTime',
                sorter: (a, b) => new Date(a.modifyTime) - new Date(b.modifyTime), // 添加最后访问时间排序
            },
            // Add more columns as per your data structure
        ],
        [pagination] // 修改依赖数组，添加 pagination
    );

    const handleTableChange = (newPagination, filters, sorter) => {
        setPagination(prev => ({
            ...prev,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        }));
    };

    const tableFooter = () => (
        <div style={{ textAlign: 'left', padding: '8px' }}>
            总条数: {pagination.total}
        </div>
    );

    return (
        <Spin spinning={loading}>
            <div style={{
                backgroundColor: '#fffbe6',
                padding: '8px 16px',
                border: '1px solid #ffe58f',
                borderRadius: '4px',
                marginBottom: '16px',
                color: '#666',
                width: '33%',
                marginLeft: 'auto',
            }}>
                选择起始时间至今{moment().diff(startTime, 'days') + 1}天内，{isRecommendedOnly ? '推荐码方式' : '所有'}{isUnpurchasedOnly ? '访问过公众号，但是距今一个月内未购买过自营套餐，且近一年内未购买移动融合套餐' : ''}的潜在用户
            </div>
            <Space style={{ marginBottom: 16, justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                <Button onClick={exportToExcel} type="primary" style={{ alignSelf: 'center' }}>
                    导出为 Excel
                </Button>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginRight: '0px' }}>检索学校：</span>
                    <Select
                        defaultValue="全部"
                        style={{ width: 140, marginRight: '8px' }}
                        onChange={(value) => setSelectedSchool(value)}
                    >
                        <Select.Option value="<ALL>" title="全部">全部</Select.Option>
                        {schoolList.map(school => (
                            <Select.Option key={school.schoolCode} value={school.schoolCode} title={school.schoolName}>
                                {school.schoolName}
                            </Select.Option>
                        ))}
                    </Select>
                    <div style={{marginLeft: '4px', marginRight: '4px', display: 'inline-flex', alignItems: 'center'}}>
                        <Tooltip title="选中则仅查询一个月内没有购买自营套餐且一年内未绑定购买移动融合套餐的用户">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    checked={isUnpurchasedOnly}
                                    onChange={(e) => setIsUnpurchasedOnly(e.target.checked)}
                                    style={{ alignSelf: 'center' }}
                                />
                                <span style={{ marginLeft: '2px' }}>未购买套餐用户</span>
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{ marginLeft: '4px', marginRight: '12px', display: 'inline-flex', alignItems: 'center' }}>
                        <Tooltip title="选中则仅查询扫推荐码进入公众号的用户，取消则查询全部进入用户">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <input
                                    type="checkbox"
                                    checked={isRecommendedOnly}
                                    onChange={(e) => setIsRecommendedOnly(e.target.checked)}
                                    style={{ alignSelf: 'center' }}
                                />
                                <span style={{ marginLeft: '2px' }}>被推荐用户</span>
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{ marginRight: '0px', display: 'inline-flex', alignItems: 'center' }}>
                        起始时间：
                    </div>
                    <ConfigProvider locale={zhCN}>
                        <DatePicker
                            format="YYYY-MM-DD"
                            value={startTime}
                            onChange={(date) => setStartTime(date.startOf('day'))} // 选择时间保留到天
                            style={{alignSelf: 'center'}}
                        />
                    </ConfigProvider>
                </div>
            </Space>
            <ConfigProvider locale={zhCN}>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={pagination}
                    onChange={handleTableChange}
                    rowKey={(record) => `${record.userId}-${record.modifyTime}`}
                    footer={tableFooter}
                />
            </ConfigProvider>
        </Spin>
    );
};

export default PotentialCustomerTable;